import React from 'react';
import Navbar from "./components/NavBar";
import Footer from "./components/Footer";

const motivationList = [
    {
        title: "Compassion",
        subtitle: "We lead with compassion",
    },
    {
        title: "Dharma",
        subtitle: "We do the right thing",
    },
    {
        title: "Work",
        subtitle: "We do hard things",
    },
    {
        title: "Courage",
        subtitle: "We chase secrets",
    },
];

const hypothesisList = [
    'AI and automation are now disrupting marketing science and analytics services',
    'Privacy is everyone\'s concern',
    'Trust and business must go hand in hand',
    'Every person is both a content creator and consumer',
    'Every business is a media company, publisher, and advertiser',
    'AI is not the answer to everything - context is king',
    'The gap between data haves and have-nots is widening',
    'There is a significant gap between academic research and business application',
    'The private internet is here to stay!',
    'Privacy, AI, and APIs are the present and future of advertising'
];

const Values: React.FC = () => {
    return (
        <>
            <Navbar/>
            <div className={"text-left text-2xl text-gray-500 font-extrabold my-12 mx-8 sm:mx-40"}>
                Our vision is to democratize marketing science, bringing it from the high towers of research and big tech to the every advertiser
            </div>
            <ul className={"text-center mt-24 mx-8 sm:mx-40 flex justify-between sm:flex-row flex-col"}>
                {motivationList.map((motivation, index) => (
                    <li key={index} className={"font-bold mb-12"}>
                        <div>
                            <div className={"text-primary font-extrabold text-4xl"}>{motivation.title}</div>
                            <div className={"text text-gray-400 mt-2"}>{motivation.subtitle}</div>
                        </div>
                    </li>
                ))}
            </ul>
            <div className={"my-8 border-t-2 border-gray-100"}></div>
            <div className={"text-left my-12 mx-8 sm:mx-40 text-2xl font-extrabold text-gray-500"}>Our 10 Hypotheses:</div>
            <ul className={"text-left mx-8 sm:mx-40 mb-24"}>
                {hypothesisList.map((hypothesis, index) => (
                    <li key={index} className={"font-bold text-secondary text-2xl mb-4"}>
                        {index + 1}.&nbsp; {hypothesis}
                    </li>
                ))}
            </ul>
            <Footer/>
        </>
    );
};

export default Values;
