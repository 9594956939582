import React from 'react';

export interface SolutionCardProps {
    imgSrc: string;
    title: string;
    useCases: string[]
}

const SolutionCard: React.FC<SolutionCardProps> = ({imgSrc, title, useCases}) => {
    return (
        <>
            <div className={"flex flex-col items-center"} style={{maxWidth: '280px'}}>
                <div className={"flex justify-center items-center mb-4"} style={{width: '200px', height: '200px'}}>
                    <img className={""} src={imgSrc} alt={""}></img>
                </div>
                <div className={"text-2xl mb-2"}>{title}</div>
                <ul className={"text-secondary"}>
                    {useCases.map((userCase, index) => (
                        <li key={index} className={"text-left mt-4 leading-8"}>{userCase}</li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default SolutionCard;
