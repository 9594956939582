import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import Values from "./values";
import Home from "./home";
import Solutions from "./solutions";
import UseCases from "./user-cases";
import Pricing from "./pricing";
import Teams from "./teams";
import Privacy from "./privacy";
import TermAndConditions from "./term-and-conditions";
import About from "./about";
import METAAGP from "./meta-agp";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="values" element={<Values/>}/>
            <Route path="solutions" element={<Solutions/>}/>
            <Route path="use-cases" element={<UseCases/>}/>
            <Route path="pricing" element={<Pricing/>}/>
            <Route path="teams" element={<Teams/>}/>
            <Route path="about" element={<About/>}/>
            <Route path="privacy" element={<Privacy/>}/>
            <Route path="meta-agp" element={<METAAGP/>}/>
            <Route path="terms-and-condition" element={<TermAndConditions/>}/>
        </Routes>
    );
}

export default App;
