import React from 'react';
import Navbar from "./components/NavBar";
import Footer from "./components/Footer";
import TeamCard, { TeamProps } from "./components/TeamCard";

const brandImages = [
    "/images/meta.png",
    "/images/google.png",
    "/images/nbcUniversal.png",
    "/images/pwd.png",
    "/images/gc.png",
    "/images/amex.png",
    "/images/disney.png",
    "/images/home-depot.jpg",
    "/images/mu-sigma.jpeg",
    "/images/era.png",
    "/images/columbia-university.png",
    "/images/harvard.png",
    "/images/nit-trichi.png"
];

const teamMembers: TeamProps[] = [
    {
        name: "Ved Prakash",
        position: "CEO",
        imgSrc: "/images/us1.jpg",
        linkedIn: "https://www.linkedin.com/in/ved-prakash",
        experience: [
            "Head of Marketing Science (Agencies) at Meta",
            "Manager at PwC",
            "Data Science lead at EXL, WNS, and Mu Sigma",
            "Columbia University, Michigan State, NIT Trichy"
        ]
    },
    {
        name: "Chase Mohney",
        position: "Chief Revenue Officer",
        imgSrc: "/images/chase.jpg",
        linkedIn: "https://www.linkedin.com/in/chase-mohney",
        experience: [
            "Agency Sales & Emerging Tech at Meta",
            "Cofounder at Trilogy Interactive",
            "Political Campaign Marketing at Blackrock",
            "Harvard University"
        ]
    },
    {
        name: "Jag Singh",
        position: "Senior Research Scientist",
        imgSrc: "/images/jag.jpg",
        linkedIn: "https://www.linkedin.com/in/jag-singh",
        experience: [
            "Senior Research Scientist at Vehant",
            "Algorithm Developer at Applied Materials",
            "Engineer at Samsung",
            "PHD from Norwegian University of Science and Technology"
        ]
    },
    {
        name: "Pratik Gurukar",
        position: "Lead Engineer",
        imgSrc: "/images/pratik.jpg",
        linkedIn: "https://www.linkedin.com/in/pratik-gurukar",
        experience: [
            "Founder at TradersOps",
            "Senior Developer at Jiraaf",
            "Engineer at Radix Health",
            "VIT Pune"
        ]
    }
];

const advisors: TeamProps[] = [
    {
        name: "Beau Avril",
        position: "Advisor",
        imgSrc: "/images/beau.png",
        linkedIn: "https://www.linkedin.com/in/beau-avril",
        experience: [
            "Director of Product Marketing at Meta",
            "Director of Business Operations at Google",
            "Board director at IAB",
            "BBA, Cornell University"
        ]
    },
    {
        name: "Aaron Radin",
        position: "Advisor",
        imgSrc: "/images/aaron.png",
        linkedIn: "https://www.linkedin.com/in/aaron-radin",
        experience: [
            "CEO at British Basketball League",
            "Board member in Brooklyn Community Services",
            "Director at Meta",
            "Senior leadership roles at Disney, CBS and NBCUniversal",
            "CEO & Founder at Toura",
            "New York University, Columbia University"
        ]
    }
];

const Teams: React.FC = () => {
    return (
        <>
            <Navbar />
            <div className="px-4 py-16">
                <h1 className="text-center my-8 text-4xl font-extrabold text-primary">Adrsta AI TEAM</h1>
                <p className="text-center my-12 text-3xl font-bold text-secondary mb-32">
                    Our team consists of experts and practitioners in AdTech, AI, and Privacy.
                </p>

                {/* Brand Logos */}
                <div className="flex flex-wrap justify-center items-center mb-16">
                    {brandImages.map((src, index) => (
                        <div key={index} className="w-44 p-2 flex justify-center mr-8 mb-8">
                            <img src={src} alt={`Brand ${index}`} className="object-contain w-full h-auto"/>
                        </div>
                    ))}
                </div>

                <p className="text-center font-extrabold text-2xl text-gray-500 sm:mx-32 mx-8 leading-8 mb-24">
                    As practitioners of Ad-tech, AI, and Privacy, our founding team is brought together by a shared vision of bringing greater efficiency to advertising using data and AI.
                </p>

                {/* Team Members */}
                <div className="flex flex-wrap justify-center gap-8 mb-16">
                    {teamMembers.map((member, index) => (
                        <div key={index} className="mt-8">
                            <TeamCard {...member} />
                        </div>
                    ))}
                </div>

                {/* Advisors Section */}
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">Adrsta AI Advisors</h2>
                <div className="flex flex-wrap justify-center gap-8 mb-16">
                    {advisors.map((advisor, index) => (
                        <div key={index} className="mt-8">
                            <TeamCard {...advisor} />
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Teams;
